import axios from "axios";

// const API_URL = "https://uprisecomputing.com:9090/api";
// "http://localhost:9090/api",
export default axios.create({
  baseURL: "https://uprisecomputing.com:9090/api",
  headers: {
    "Content-type": "application/json"
  }
});


