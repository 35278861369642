

import React from 'react';
import Slide01 from './static/images/slider-01.jpg';
import Slide02 from './static/images/slider-02.jpg';
import Slide03 from './static/images/slider-03.jpg';
//import logo from '../images/xvlogo.png';

const Home = () => {
return (
     <div id="appBody">


     				<div id="home" className="first-section" style={{ backgroundImage: `url(require("images/slider-01.jpg"))`}} >

                        <br />
                        <br />

                         <div className="container">
                                  <h2><strong>Software Training & Development</strong></h2>
                                  <div id="myCarousel" className="carousel slide" data-ride="carousel">

                                    <ol className="carousel-indicators">
                                      <li data-target="#myCarousel" data-slide-to="0" className="active"></li>
                                      <li data-target="#myCarousel" data-slide-to="1"></li>
                                      <li data-target="#myCarousel" data-slide-to="2"></li>
                                    </ol>

                               <div className="carousel-inner">
                                      <div className="item active">
                                        <img src={Slide01} alt="Los Angeles" style={{ width: "100%"}}  />
                                      </div>

                                      <div className="item">
                                        <img src={Slide02} alt="Chicago" style={{ width: "100%"}} />
                                      </div>

                                      <div className="item">
                                        <img src={Slide03} alt="New york" style={{ width: "100%"}}  />
                                      </div>
                                    </div>

                                    <a className="left carousel-control" href="#myCarousel" data-slide="prev">
                                      <span className="glyphicon glyphicon-chevron-left"></span>
                                      <span className="sr-only">Previous</span>
                                    </a>
                                    <a className="right carousel-control" href="#myCarousel" data-slide="next">
                                      <span className="glyphicon glyphicon-chevron-right"></span>
                                      <span className="sr-only">Next</span>
                                    </a>
                                  </div>
                         </div>

     					 <div className="dtab">
     						<div className="container">
     							<div className="row">
     								<div className="col-md-12 col-sm-12 text-right">
     									<div className="big-tagline">
     										<p className="lead">With Landigoo responsive landing page template, you can promote your all hosting, domain and email services. </p>
     											<a href="#" className="hover-btn-new"><span>Contact Us</span></a>
     											&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
     											<a href="#" className="hover-btn-new"><span>Read More</span></a>
     									</div>
     								</div>
     							</div>
     						</div>
     					</div>
     				</div>




           <div className="search-container">
                  <form action="/action_page.php">
                    <input type="text" placeholder="Search.." name="search" />
                    <button type="submit"><i className="fa fa-search"></i></button>
                  </form>
            </div>


            <br />
            <br />



{
/*
            <div className="row">
                    <div c>
                      <div className="card">
                        <h2>TITLE HEADING</h2>
                        <h6>Title description, Dec 7, 2017</h6>
                        <div className="fakeimg" style={{height:200}}>Image</div>
                        <p>Some text..</p>
                        <p>Sunt in culpa qui officia deserunt mollit anim id est laborum consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.</p>
                      </div>

                    </div>
                    <div >
                      <div className="card">
                        <h2>About Me</h2>
                        <div className="fakeimg" style={{height:50}}>Image</div>
                        <p>Some text about me in culpa qui officia deserunt mollit anim..</p>
                      </div>
                      <div class="card">
                        <h3>Popular Post</h3>
                        <div className="fakeimg">Image</div>

                        <div className="fakeimg">Image</div>

                        <div className="fakeimg">Image</div>
                      </div>
                      <div className="card">
                        <h3>Follow Me</h3>
                        <p>Some text..</p>
                      </div>
                    </div>
            </div>*/

 }

      </div>
    );
};
export default Home;