import React, { useState, useEffect } from 'react';
//import BlogService from '../services/BlogService';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Card, Row, Col, Container } from "react-bootstrap";
import FormDialog from "../../admin/components/FormDialog";

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';

import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

//import upriseLogo from '../static/images/uprise_logo.png';
import transportationImage1 from '../../static/images/transportation_01.jpg';
import transportationImage2 from '../../static/images/truck-460432_1280.jpg';
import bankingImage from '../../static/images/bankingImage.jpg';
import healthCareImage from '../../static/images/healthCareImage.jpg';
import publicServiceImage from '../../static/images/publicServiceImage.jpg';
import educationImage from '../../static/images/educationImage.jpg';
import eCommerceImage from '../../static/images/eCommerceImage.jpg';
import eCommerceImage2 from '../../static/images/eCommerceImage.jpg';
import insuranceImage from '../../static/images/insuranceImage.jpg';
import medicalImageViewer from '../../static/images/MedprimeGlobal.png';


import img1 from '../../static/images/img-1.png';
import img2 from '../../static/images/img-2.png';
import img02 from '../../static/images/img-02.jpg';
import img3 from '../../static/images/img-3.png';
import img03 from '../../static/images/img-03.jpg';
import img04 from '../../static/images/img-04.jpg';

const SoftwareDevDashboard = () => {

     // const [categories, setCategories] = useState([]);
    /*
      useEffect(() => {
        BlogService.getCategories().then((newCategories) => {
          setCategories(newCategories.data);
        });
        console.log('categories: '+categories);
        console.log('categories length: '+categories.length);
      }, []);*/


 let clientId = "DfFCvW2ViuP8uyRPl1_yCobGqMXUF0m9qlX27WtWVMU";
 let query ="computing";
 let url = "https://api.unsplash.com/search/photos/?client_id=" +
           clientId +
           "&query=" +
            query


    const [playerData, setPlayerData] = useState([]);
    useEffect(() => {

        const fetchData = async () => {
            //const response = await fetch('https://nba-players.herokuapp.com/players-stats')
            //const response = await fetch(url)
            //const nbaData = await response.json()
            //setPlayerData(nbaData.slice(0, 15))

            const response = await fetch(url)
            const nbaData = await response.json()

            console.log("data: "+ JSON.stringify(nbaData));

            setPlayerData(nbaData);
        }
        fetchData()
    }, [])

    const DemoPaper = styled(Paper)(({ theme }) => ({
    width: 340,
    height: 340,
    padding: theme.spacing(2),
    ...theme.typography.body2,
    textAlign: 'center',
    }));

/*
let transportationImage1 = "transportation_01.jpg";
let transportationImage2 = "truck-460432_1280.jpg";
let bankingImage = "bankingImage.jpg";
let healthCareImage = "healthCareImage.jpg";
let publicServiceImage = "publicServiceImage.jpg";
let educationImage = "educationImage.jpg";
let eCommerceImage = "eCommerceImage.jpg";
let eCommerceImage2 = "eCommerceImage2.jpg";
let insuranceImage = "insuranceImage.jpg";

<img
        srcSet={transportationImage1}
        src={process.env.PUBLIC_URL + `/images/dev/${transportationImage1}?w=248&fit=crop&auto=format`}
        alt="Transportation"
        loading="lazy"
        width="248"
    />
*/

const contactUsDivStyle = {
      color: "black",
      backgroundColor: "#b3e0dc",
      borderRadius: "20px",
      padding: "5px",
      fontFamily: "Arial",
      float: "right",
      marginTop: "3px",
      marginRight: "3px",
      width: "210px",
      height: "80px"
    };

  return (

      <Container>

            <div style={contactUsDivStyle} >
                <p>Reach out to us</p>
                <div>Phone:  33468585</div>
                <div>Email: contact@uprisecomputing.com</div>
            </div>

             <div className="container">
                        <p>Who We Are</p>
                        <div className="row">
                          We are a fast-growing technology & Software company, with solid reputation in building
                           <br />
                          state-of-art applications with diverse technology platforms.
                        </div>
                        <br />

                        <p>Our Products/Services</p>
                        <div className="row">
                               <div>
                                <Stack direction="row" spacing={1}>

                                        <DemoPaper square={false}>
                                           <ImageList cols={3} gap={3}
                                                      sx={{
                                                        gridTemplateColumns:
                                                          'repeat(auto-fill, minmax(250px, 1fr))!important',
                                                      }}

                                                      >
                                                 <ImageListItem key="1" >

                                                           <img
                                                                    srcSet={transportationImage1}
                                                                    src={transportationImage1}
                                                                    alt="Transportation"
                                                                    loading="lazy"
                                                                    width="248"
                                                                />

                                                            <img
                                                                srcSet={transportationImage1}
                                                                src={transportationImage1}
                                                                alt="Transportation"
                                                                loading="lazy"
                                                                width="248"
                                                            />
                                                            <ImageListItemBar
                                                                title="Transportation Logistics Software. Transportation Management"
                                                                subtitle="Logistics"
                                                                position="below"
                                                            />

                                                  </ImageListItem>
                                          </ImageList>
                                        </DemoPaper>
                                        <DemoPaper square={false}>
                                           <ImageList cols={3} gap={3}
                                                      sx={{
                                                        gridTemplateColumns:
                                                          'repeat(auto-fill, minmax(250px, 1fr))!important',
                                                      }}

                                                      >
                                                 <ImageListItem key="1" >
                                                            <img
                                                                srcSet={bankingImage}
                                                                src={bankingImage}
                                                                alt="Banking & Payment"
                                                                loading="lazy"
                                                                width="248"
                                                            />
                                                            <ImageListItemBar
                                                                title="Banking & Payment"
                                                                subtitle="Consumer and Community Banking. Payment System"
                                                                position="below"
                                                            />
                                                  </ImageListItem>
                                          </ImageList>
                                        </DemoPaper>

                                        <DemoPaper square={false}>
                                        <ImageList cols={3} gap={3}
                                              sx={{
                                                gridTemplateColumns:
                                                  'repeat(auto-fill, minmax(250px, 1fr))!important',
                                              }}

                                              >
                                         <ImageListItem key="1" >
                                                    <img
                                                        srcSet={healthCareImage}
                                                        src={healthCareImage}
                                                        alt="Transportation"
                                                        loading="lazy"
                                                        width="248"
                                                    />
                                                    <ImageListItemBar
                                                        title="HealthCare"
                                                        subtitle="Medical Network Support Application. Medical Report System. DiCOM Medical Image Viewer"
                                                        position="below"
                                                    />
                                          </ImageListItem>

                                        </ImageList>
                                        </DemoPaper>





<DemoPaper square={false}>
<ImageList cols={3} gap={3}
      sx={{
        gridTemplateColumns:
          'repeat(auto-fill, minmax(250px, 1fr))!important',
      }}

      >
    <ImageListItem key="1" >
              <img
                  srcSet={medicalImageViewer}
                  src={medicalImageViewer}
                  alt="HealthCare"
                  loading="lazy"
                  width="248"
              />
              <ImageListItemBar
                  title="HealthCare"
                  subtitle="Medical Network Support Application. Medical Report System. DiCOM Medical Image Viewer"
                  position="below"
              />
    </ImageListItem>
 </ImageList>
</DemoPaper>

                                 </Stack>
                               </div>

                               <br />
                               <div>
                               <Stack direction="row" spacing={1}>

                                   <DemoPaper square={false}>
                                      <ImageList cols={3} gap={3}
                                                 sx={{
                                                   gridTemplateColumns:
                                                     'repeat(auto-fill, minmax(250px, 1fr))!important',
                                                 }}

                                                 >
                                            <ImageListItem key="1" >
                                                       <img
                                                           srcSet={educationImage}
                                                           src={educationImage}
                                                           alt="Education"
                                                           loading="lazy"
                                                           width="248"
                                                       />
                                                       <ImageListItemBar
                                                           title="Education"
                                                           subtitle="School Management. Examination & Evaluation System"
                                                           position="below"
                                                       />
                                             </ImageListItem>
                                     </ImageList>
                                   </DemoPaper>
                                   <DemoPaper square={false}>
                                      <ImageList cols={3} gap={3}
                                                 sx={{
                                                   gridTemplateColumns:
                                                     'repeat(auto-fill, minmax(250px, 1fr))!important',
                                                 }}

                                                 >
                                            <ImageListItem key="1" >
                                                       <img
                                                           srcSet={publicServiceImage}
                                                           src={publicServiceImage}
                                                           alt="Public Service"
                                                           loading="lazy"
                                                           width="248"
                                                       />
                                                       <ImageListItemBar
                                                           title="Public Service"
                                                           subtitle="Public Service & Administration System"
                                                           position="below"
                                                       />
                                             </ImageListItem>
                                     </ImageList>
                                   </DemoPaper>
                                   <DemoPaper square={false}>
                                   <ImageList cols={3} gap={3}
                                         sx={{
                                           gridTemplateColumns:
                                             'repeat(auto-fill, minmax(250px, 1fr))!important',
                                         }}

                                         >
                                    <ImageListItem key="1" >
                                               <img
                                                   srcSet={eCommerceImage}
                                                   src={eCommerceImage}
                                                   alt="eCommerce & Retail"
                                                   loading="lazy"
                                                   width="248"
                                               />
                                               <ImageListItemBar
                                                   title="eCommerce & Retail"
                                                   subtitle="Customer Front Enterprise Portal"
                                                   position="below"
                                               />
                                     </ImageListItem>
                                   </ImageList>
                                   </DemoPaper>

                            </Stack>

                          </div>

                    </div>

                 </div>




                    <div className="courses_section layout_padding">
                             <div className="container">
                                <div className="row">
                                   <div className="col-sm-12">
                                      <h1 className="courses_taital">Technology in action</h1>
                                   </div>
                                </div>
                                <div id="main_slider" className="carousel slide" data-ride="carousel">
                                   <div className="carousel-inner">
                                      <div className="carousel-item active">
                                         <div className="courses_section_2">
                                            <div className="row">
                                               <div className="col-lg-4">
                                                  <div className="hover01 column">
                                                     <figure><img src={img1} /></figure>
                                                  </div>
                                                  <h3 className="introduction_text">Introduction to CSS</h3>
                                                  <p className="lorem_text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et d</p>
                                               </div>
                                               <div className="col-lg-4">
                                                  <div className="hover01 column">
                                                     <figure><img src={img2} /></figure>
                                                  </div>
                                                  <h3 className="introduction_text">The Complete Web Development </h3>
                                                  <p className="lorem_text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et d</p>
                                               </div>
                                               <div className="col-lg-4">
                                                  <div className="hover01 column">
                                                     <figure><img src={img3} /></figure>
                                                  </div>
                                                  <h3 className="introduction_text">PHP Tips, Tricks, and Techniques</h3>
                                                  <p className="lorem_text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et d</p>
                                               </div>
                                            </div>
                                         </div>
                                      </div>
                                      <div className="carousel-item">
                                         <div className="courses_section_2">
                                            <div className="row">
                                               <div className="col-lg-4">
                                                  <div className="hover01 column">
                                                     <figure><img src={img1} /></figure>
                                                  </div>
                                                  <h3 className="introduction_text">Introduction to CSS</h3>
                                                  <p className="lorem_text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et d</p>
                                               </div>
                                               <div className="col-lg-4">
                                                  <div className="hover01 column">
                                                     <figure><img src={img2} /></figure>
                                                  </div>
                                                  <h3 className="introduction_text">The Complete Web Development </h3>
                                                  <p className="lorem_text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et d</p>
                                               </div>
                                               <div className="col-lg-4">
                                                  <div className="hover01 column">
                                                     <figure><img src={img3} /></figure>
                                                  </div>
                                                  <h3 className="introduction_text">PHP Tips, Tricks, and Techniques</h3>
                                                  <p className="lorem_text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et d</p>
                                               </div>
                                            </div>
                                         </div>
                                      </div>
                                      <div className="carousel-item">
                                         <div className="courses_section_2">
                                            <div className="row">
                                               <div className="col-lg-4">
                                                  <div className="hover01 column">
                                                     <figure><img src={img1} /></figure>
                                                  </div>
                                                  <h3 className="introduction_text">Introduction to CSS</h3>
                                                  <p className="lorem_text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et d</p>
                                               </div>
                                               <div className="col-lg-4">
                                                  <div className="hover01 column">
                                                     <figure><img src={img2} /></figure>
                                                  </div>
                                                  <h3 className="introduction_text">The Complete Web Development </h3>
                                                  <p className="lorem_text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et d</p>
                                               </div>
                                               <div className="col-lg-4">
                                                  <div className="hover01 column">
                                                     <figure><img src={img3} /></figure>
                                                  </div>
                                                  <h3 className="introduction_text">PHP Tips, Tricks, and Techniques</h3>
                                                  <p className="lorem_text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et d</p>
                                               </div>
                                            </div>
                                         </div>
                                      </div>
                                   </div>
                                   <a className="carousel-control-prev" href="#main_slider" role="button" data-slide="prev">
                                   <i className="fa fa-angle-left"></i>
                                   </a>
                                   <a className="carousel-control-next" href="#main_slider" role="button" data-slide="next">
                                   <i className="fa fa-angle-right"></i>
                                   </a>
                                </div>
                             </div>
                          </div>

              <br />
              <br />



               <div id="support" className="section wb">
                      <div className="container-fulid">
                          <div className="section-title text-center">
                              <h3>Need Help? Sure we are here!</h3>
                              <p className="lead">Let us give you more details about the special offer website you want us. Please fill out the form below. <br />We have million of website owners who happy to work with us!</p>
                          </div>

                          <div className="row">
                              <div className="col-xl-6 col-md-12 col-sm-12">
                                  <div className="contact_form">
                                      <div id="message"></div>
                                      <form id="contactform" className="" action="contact.php" name="contactform" method="post">
                                          <fieldset className="row row-fluid">
                                              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                  <input type="text" name="first_name" id="first_name" className="form-control" placeholder="First Name" />
                                              </div>
                                              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                  <input type="text" name="last_name" id="last_name" className="form-control" placeholder="Last Name" />
                                              </div>
                                              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                  <input type="email" name="email" id="email" class="form-control" placeholder="Your Email" />
                                              </div>
                                              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                  <input type="text" name="phone" id="phone" className="form-control" placeholder="Your Phone" />
                                              </div>
                                              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                  <textarea className="form-control" name="comments" id="comments" rows="6" placeholder="Give us more details.."></textarea>
                                              </div>
                                              <div className="text-center pd">
                                                  <button type="submit" value="SEND" id="submit" className="btn btn-light btn-radius btn-brd grd1 btn-block">Get a Quote</button>
                                              </div>
                                          </fieldset>
                                      </form>
                                  </div>
                              </div>

                          </div>
                      </div>
                  </div>



              <br />
              <br />

        </Container>



  );

};

export default SoftwareDevDashboard;