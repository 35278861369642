import axios from "axios";
import qs from 'qs';

// https://stackoverflow.com/questions/44617825/passing-headers-with-axios-post-request
// https://blog.logrocket.com/how-to-make-http-requests-like-a-pro-with-axios/

//const API_URL = "http://localhost:8080/api/auth/";

// Error in actaul server
//https://stackoverflow.com/questions/18251128/why-am-i-suddenly-getting-a-blocked-loading-mixed-active-content-issue-in-fire
// onst API_URL = "//uprisecomputing.com:8080/api/auth/";
// const API_URL = "http://uprisecomputing.com:8080/api/auth/";

//const API_URL = "https://153.92.214.21:9090/api/auth/"; //worked with post man http

const API_URL = "https://uprisecomputing.com:9090/api/auth/";

 // /api/auth/signup
class AuthService {

  login(username, password) {
     console.log("In login() ");
    return axios
      .post(API_URL + "signin", { username: username, password: password })
      .then((response) => {
             console.log("AuthService 1 - JSON.stringify(response.data): "+ JSON.stringify(response.data));
        if(response) {

            console.log("AuthService 2 - JSON.stringify(response.data): "+ JSON.stringify(response.data));

            if (response.data.accessToken) {
              localStorage.setItem("user_byte_steroid", JSON.stringify(response.data));
            }
            return response.data;
        }
      });
     //=======================
    //
  }


authenticateUser(username, password) {
     console.log("In authenticateUser() ");
    return axios
     // .post("http://localhost:8080/api/auth/user/authentication", { userName: username, password: password })
     .post(API_URL + "user/authentication", { userName: username, password: password })
      .then((response) => {
             console.log("AuthService - JSON.stringify(response.data): "+ JSON.stringify(response.data));
        if(response) {

            console.log("AuthService  - JSON.stringify(response.data): "+ JSON.stringify(response.data));

            if (response.data.accessToken) {
              localStorage.setItem("user_byte_steroid", JSON.stringify(response.data));
            }
            return response.data;
        }
      });
     //=======================

  }


 refreshToken(refreshToken) {

  /// /api/auth/refreshtoken
           // Post with String refreshToken


     console.log("In refreshToken() ");
    return axios
      .post(API_URL + "refreshtoken", { refreshToken: refreshToken})
      .then((response) => {
             console.log("AuthService refreshtoken - JSON.stringify(response.data): "+ JSON.stringify(response.data));
        if(response) {
            console.log("AuthService refreshtoken - JSON.stringify(response.data): "+ JSON.stringify(response.data));
            if (response.data.accessToken) {
              localStorage.setItem("user_byte_steroid", JSON.stringify(response.data));
            }
            return response.data;
        }
      });
     //=======================

  }

  logout() {
    localStorage.removeItem("user_byte_steroid");
  }

  register(userData) {
     //alert("userData :"+ JSON.stringify(userData))
     return axios.post(API_URL + "signup", userData);

  } //========= end register method


  sendVerificationEmail(email)  {
   return axios.post(API_URL + "resetPassword/emailverification", null, { params: {
      email
    }})
     .then((response) => {
            if(response) {
                console.log("sendVerificationEmail - JSON.stringify(response.data): "+ JSON.stringify(response.data));
                return response.data;
            }
          }).catch(err => {
             console.log(err);
          });
  }


  savePassword(data)  {
      return axios.post(API_URL + "save/password", data, { params: {
      }})
       .then((response) => {
              if(response) {
                  console.log("savePassword - JSON.stringify(response.data): "+ JSON.stringify(response.data));
                  return response.data;
              }
            }).catch(err => {
              console.log(err);
           });
    }


    //=============
    // https://axios-http.com/docs/urlencoded
    // https://stackoverflow.com/questions/43871637/no-access-control-allow-origin-header-is-present-on-the-requested-resource-whe
    //================



   /* console.log('url in signup call: '+url);
    console.log('username in signup call: '+username);
    console.log('email in signup call: '+email);
    console.log('password in signup call: '+password);

      //const postData = { firstName: 'Desmond', lastName: 'Amadigwe', userName: username, email : email, password: password};
      const url = API_URL + "signup";
      const postData = { 'username' : username, 'email' : email, 'password' : password};
      let axiosConfig = {
       headers: {
           'Content-Type': 'application/json;charset=UTF-8',
           "Access-Control-Allow-Origin": "*",
       }
     };

     axios.post(url, postData, axiosConfig)
     .then((res) => {
       console.log("RESPONSE RECEIVED: ", res);
     })
     .catch((err) => {
       console.log("AXIOS ERROR: ", err);
     })
*/





}

export default new AuthService();
