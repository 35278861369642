import axios from "axios";
import authHeader from "./auth-header";
import http from "../http-common";

const API_URL = "http://localhost:9090/api/test/";

class UserService {

  getPublicContent() {
    return axios.get(API_URL + "all");
  }

  getAllUsers() {
     return http.get('/user/retrieval/all');
  }

 fetchUserById(id) {
        return http.get('/user/retrieval/'+id);
    }


 updateUser(data)  {
      return http.post(API_URL + "/user/update", data, { params: {
      }})
       .then((response) => {
              if(response) {
                  console.log("updateUser - JSON.stringify(response.data): "+ JSON.stringify(response.data));
                  return response.data;
              }
            }).catch(err => {
              console.log(err);
           });
    }


  getUserBoard() {
    return axios.get(API_URL + "user", { headers: authHeader() });
  }

  getModeratorBoard() {
    return axios.get(API_URL + "mod", { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL + "admin", { headers: authHeader() });
  }
}

export default new UserService();
